.testiWidth {
  width: 100%;
  margin-right: auto;
}

.new_testimonal {
  /* width: 88%;
  border-radius: 10px;
  background: linear-gradient(to right, #fff4eb, #ffdfd8);
  box-shadow: 4px -2px 1px #e6e5e5; */
}
.slick-slide {
  padding: 0 10px;
}

/* the parent */
.slick-list {
  margin: 0 0px;
}

.newtesti_content::before {
  content: url("../assets/new-testimonal/topVector.png"); /* URL of your image */
}

.newtesti_content2::after {
  content: url("../assets/new-testimonal/bottomVector.png");
  height: 20px; /* URL of your image */
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .testiWidth {
    width: 100%;
    margin: auto;
  }
  .slick-slide {
    padding: 0 10px;
  }

  /* the parent */
  .slick-list {
    margin: 0 0px;
  }
}
