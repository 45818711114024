.parp-container {
    width: 100%;
}

.hero-section-parp {
    background-image: url("privacy.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}