.herowrapper {
  background-image: url("../assets/Hero/herobg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}

@media screen and (min-width:320px) and (max-width:480px) {
  .herowrapper {
    background-position: center center;
  }
}

.mobile-view>p {
  display: none;
}

@media (min-width:320px) and (max-width:480px) {
  .desktop-view>p {
    display: none;

  }

  .mobile-view>p {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

@media (min-width:480px) and (max-width:768px) {
  .desktop-view>p {
    display: none;

  }

  .mobile-view>p {
    display: block;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}