.footer-subhead li{
    font-size: 14px;
}
.qrimage{
    height: 6rem;
    width: 100%;
}

@media screen and (min-width:320px) and (max-width:767px){
    .qrimage{
        height: 4rem;
    }
}