.p-header {
  font-family: Gilroy;
  font-size: 64px;
  line-height: 69.23px;

  font-size: 36px;
  font-weight: 800;
  line-height: 60.23px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.p-header-des {
  font-family: Gilroy;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

@media (max-width: 468px) {
  .p-header {
    font-family: Gilroy;
    font-weight: 800;
    font-size: 40px;
  }

  .p-header-des {
    font-family: Gilroy;
    font-weight: 400;
    font-size: 18px;
  }

  .back-div {
    position: absolute;
    /* top: 10px; */
  }

  .front-div {
    position: relative;
    bottom: 4px;

    /* bottom: 10px; */
  }
}
